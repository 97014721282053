<template>
    <div class="box">
        <div class="login">
            <div class="login-header">
                <div>鑫易付代理商登录</div>
                <div>HI !</div>
                <div>欢迎来到鑫易付</div>
            </div>
            <div class="login-main">
                <el-form :model="form">
                    <el-form-item>
                        <el-input type="text" v-model="form.phone" placeholder="手机号" />
                    </el-form-item>
                    <el-form-item>
                        <el-input type="password" v-model="form.password" show-password :auto-insert-space="true"
                            placeholder="密码" />
                    </el-form-item>
                    <el-from-item>
                        <el-button color="#fff" :dark="true" size="large" type="primary" @click="onSubmit"
                            v-loading="isLoading">{{ loginText }}
                        </el-button>
                    </el-from-item>
                </el-form>
            </div>
            <!-- <div class="resetPassword">
                <text @click="goToresetPassword">忘记密码？</text>
            </div> -->
        </div>
    </div>
</template>

<script>
import message from '@/tools/messageTools.js'

export default {
    data() {
        return {
            form: {
                phone: '',
                password: ''
            },
            loginText: '登 录',
            isLoading: false,
        }
    },
    methods: {
        async onSubmit() {
            this.loginText = '登 录 中...'
            this.isLoading = true
            const r = await this.$api.login({ phone: this.form.phone, password: this.form.password })

            if (r.status == 200 && r.success) {
                const token = {
                    access_token: r.data.access_token,
                    expires_in: r.data.expires_in,
                    token_type: r.data.token_type,
                    begin_time: (Date.parse(new Date()) / 1000)
                }

                sessionStorage.setItem('token', JSON.stringify(token))
                sessionStorage.setItem('agent_user', JSON.stringify(r.data.user))

                this.$router.push({ path: '/dashboard' })
            } else {
                message.message(r)
                this.isLoading = false
                this.loginText = '登 录'
            }
        },
        goToresetPassword() {
            this.$router.push({ path: '/resetPassword' })
        }
    }
}
</script>

<style lang="less" scoped>
.box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(to bottom left, #2d3e69, #4d6fbf);
    overflow: hidden;

    .login {
        width: 400px;
        height: 300px;
        position: absolute;
        top: 50%;
        left: 50%;
        text-align: center;
        margin-top: -150px;
        margin-left: -200px;
        background-image: linear-gradient(to top, #4d6fbf, #2d3e69);
        border-radius: 15px;

        @width: 250px;

        .login-header {
            width: 100%;
            height: 80px;
            line-height: 80px;
            padding-top: 15px;
            color: #fff;

            >div {
                width: @width;
                height: 20px;
                line-height: 20px;
                margin: 0 auto;
                text-align: left;
                font-size: 14px;
            }

            >div:first-child {
                height: 40px;
                line-height: 40px;
                font-size: 20px;
            }
        }

        .login-main {
            width: @width;
            margin: 0 auto;
            margin-top: 25px;

            .el-button--primary {
                background: #2d3e69 !important;
                border-color: #2d3e69 !important;
                color: #FFF !important;

                .el-loading-mask {
                    background-color: transparent !important;
                }
            }

        }

        .resetPassword {
            display: flex;
            justify-content: flex-end;
            margin-right: 20px;
            color: #FFF;
        }

        .resetPassword:hover {
            color: #2d3e69;
        }

    }
}
</style>